.wrapper {
    margin: 1rem;
    height: 90vh;
    display: grid;
    grid-template-columns: 2fr 10fr;
    grid-column-gap: 5.9rem;
    grid-row-gap: 3rem;
    overflow: hidden;
}

.sidebar{
    overflow-y: scroll;
}
.container{
    overflow-y: scroll;
}

