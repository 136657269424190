.formWrapper {
   .header {
      position: relative;
      padding-bottom: 1rem;
   }
   .formData {
      padding-top: 2rem;
      display: grid;
      grid-auto-flow: row;
      grid-row-gap: 1.5rem;
      min-width: 32.3rem;
   }
   .signupBtn {
      padding-top: 3rem;
      display: grid;
      justify-content: left;
   }
}
.loginConfirm {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   text-align: center;
   min-width: 320px;

   img {
      width: 60px;
    }

    p {
      margin: 20px 0;
      font-size: 13px;
      color: #212121;
    }
 }