.wrapper{
    padding: 3rem 5rem; 
    height: calc(100vh - 9rem);
    width: 60rem;
    .btn{
        display: grid;
        grid-auto-flow: column;
        gap: 2rem;
        margin-bottom: 2rem;
        h6{
            font-size: 1.6rem;
            font-weight: 600;
            margin: 0 !important;
        }
    }
    section{
        background: var(--white);
        border: 1px solid var(--border-1);
        border-radius: 0.4rem;
        box-shadow: var(--shadow-10);
    }
}