.wrapper {
    display: grid;
    grid-template-columns: 10rem auto;
    .mainWrapper {
        display: grid;
        grid-template-rows: 7.8rem 1fr;
    }
    .contentWrapper {
        display: grid;
        grid-template-columns: 28rem 1fr;
        .content {
            padding: 3rem;
            height: 100vh;
            overflow: hidden;
        }
    }
}
