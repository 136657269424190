
.formWrapper {
   .header {
      position: relative;
      padding-bottom: 1rem;
   }
   .small {
      font-size: 1.2rem;
      color: var(--text-6);
   }
   .formData {
      padding-top: 2rem;
      display: grid;
      grid-auto-flow: row;
      grid-row-gap: 1.5rem;
      min-width: 32.3rem;
   }
   .signUpOptions {
      padding: 1.2rem 0;
      display: grid;
      align-items: center;
      grid-template-columns: max-content max-content 1fr;
      grid-gap: 1.4rem;
      .line {
         background: var(--primary);
         height: 0.2rem;
         width: 4.5rem;
      }
      .otherOptions {
         color: var(--primary);
      }
   }
   .thirdPartyBtn {
      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: 1rem;
      span {
         font-size: 1.1rem;
      }
   }
   .signupBtn {
      padding-top: 3rem;
      display: grid;
      justify-content: left;
   }
}
.banner {
   .slider {
      display: grid;
      align-items: center;
   }
   .footer {
      display: grid;
      text-align: center;
      grid-gap: 1rem;
      justify-items: center;
      h4 {
         text-align: center;
      }
      span {
         font-size: 0.9rem;
         text-align: center;
         width: 27rem;
      }
   }
   .dots {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 1rem;
      justify-content: center;
      padding-top: 5rem;
      .active {
         width: 1.1rem;
         height: 0.4rem;
         border-radius: 0.5rem;
         background: var(--primary);
      }
      .circle {
         width: 0.4rem;
         height: 0.4rem;
         border-radius: 50%;
         background: var(--border-2);
         cursor: pointer;
      }
   }
}
.wrapper {
   width: 100%;
   height: 100vh;
   display: grid;
   justify-content: center;
   align-items: center;
   .bgImageCls {
      display: grid;
      justify-content: center;
      align-items: center;
      background-image: url("./shape1.png");
      filter: blur(.2rem);
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 30rem;
      left: 0rem;
   }
   .bgImageRight{
      display: grid;
      justify-content: center;
      background-image: url("./bg2.png");
      filter: blur(1rem);
      height: 100%;
      background-position: top;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 40rem;
      bottom: 0;
      right: 4rem;
   }
   .bgImageRight2{
      display: grid;
      justify-content: center;
      background-image: url("./bg1.png");
      filter: blur(.4rem);
      height: 100%;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 40rem;
      bottom: 0;
      right: 4rem;
   }
   .bgImage {
      position: absolute;
      z-index: -1;
      top: 0;
      display: none;
      opacity: 0.8;
      left: 0;
   }
   .login {
      padding-top: 3rem;
      width: 92rem;
      height: 58.8rem;
      border-radius: 2rem;
      display: grid;
      align-items: center;
      justify-content: center;
      justify-items: center;
      background: var(--white);
      grid-template-columns: 1fr 1fr;
      box-shadow: var(--shadow-6);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
   }
   .forgot {
      cursor: pointer;
      justify-self: right;
      &:hover {
         color: var(--primary);
      }
   }
}
