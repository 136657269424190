@font-face{
    font-family:"roboto";
    src:url("./roboto/Roboto-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face{
    font-family:"roboto";
    src:url("./roboto/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face{
    font-family:"roboto";
    src:url("./roboto/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face{
    font-family:"roboto";
    src:url("./roboto/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}


@font-face{
    font-family:"roboto";
    src:url("./roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face{
    font-family:"roboto";
    src:url("./roboto/Roboto-Black.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
