.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
    padding: 0 0 0.4rem !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}

.ant-form-item-label > label {
    color: var(--text-7) !important;
    font-size: 1.2rem !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 0.2rem !important;
    color: var(--error) !important;
    font-size: 1.4rem !important;
    content: "*";
}

.ant-form-item {
    margin-bottom: 1.5rem !important;
}

.ant-form-item-explain-error {
    color: var(--error) !important;
}

.ant-result {
    display: grid;
    align-content: center;
    justify-content: center;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    background: var(--child-nav-primary) !important;
}


.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
    margin: 0 !important;
    height: 4.7rem !important;
    padding: 0 2.4rem !important;
}
.ant-menu-submenu-inline {
    border-bottom: 1px dashed var(--title-primary);
}

.ant-menu-title-content{
    color: var(--text-3);
    font-size: 1.3rem;
}

.ant-menu-item-selected{
    .ant-menu-title-content{
        color: var(--primary) !important;
    }
}

// .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
//     border-right: none !important;
// }

.ant-menu-submenu-arrow{
    left: 16px !important;
    right: unset !important;
}

.ant-menu-title-content{
    padding-left: 1.5rem;
}

.ant-btn > span{
    text-transform: capitalize;
    font-weight: 500;
}