@import "./reset.scss";
@import "./less/override.scss";
@import "./fonts/roboto.scss";

html {
  font-size: 10px;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 6rem 1fr;
}

html,
body,
.appContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

@media only screen and (min-width: 1600px) {
  html {
    font-size: 11px;
  }
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 10px;
  }
}
*:focus:not(:focus-visible) {
  outline: none;
}
.sidebar {
  overflow-y: scroll;
  border-right: 1px solid var(--primary);
  .ant-menu-inline {
    background-color: #ffffff !important;
    border: none;
  }
}
.contentContainer {
  overflow: scroll;
  img {
    width: 75%;
  }
  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
  }

  // ul {
  //   display: block;
  //   list-style-type: disc;
  //   margin-top: 1em;
  //   margin-bottom: 1 em;
  //   margin-left: 0;
  //   margin-right: 0;
  //   padding-left: 40px;
  //   li {
  //     display: list-item;
  //   }
  // }
  // ol{
  //   display: block;
  //   list-style-type: decimal;
  //   margin-top: 1em;
  //   margin-bottom: 1em;
  //   margin-left: 0;
  //   margin-right: 0;
  //   padding-left: 20px;
  //   list-style-position: inside;
  // }
  // code{
  //   font-family: monospace;
  //   background-color: var(--info);
  // }
}



.profile{
  min-width: 20rem;
  max-width: 25rem;
  padding: 0;
  ul{
    margin: 0 !important;
    padding: 0 !important;
    li{
      padding: .8rem 1.2rem;
    }
    .channelList{
      border-bottom: 1px solid var(--border-1);
      .list{
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        span{
          line-height: unset !important;
          font-size: 1rem !important;
          margin-right: 0 !important;
        }
      }
    }
    .logout{
      cursor: pointer;
      font-weight: 500;
      &:hover{
        background: var(--border-1);
      }
    }
  }
}