.wrapper {
    margin: 1rem;
    height: 90vh;
    display: grid;
    // grid-template-columns: 10fr 2fr;
    grid-template-columns: 2fr 10fr;
    grid-column-gap: 5.9rem;
    grid-row-gap: 3rem;
    overflow: hidden;
}

.sidebar{
    overflow-y: scroll;
    border-right: 1px solid var(--primary);
    .ant-menu-inline {
      background-color: #ffffff !important;
      border: none;
    }
}
.container{
    overflow-y: scroll;
}

